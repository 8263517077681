import * as THREE from "three";
import Experience from "../Experience.js";
import Stars from "./Stars.js";
import Floor from "./Floor.js";
import Butterflies from "./Butterflies.js";
import DNAbackboneVertexShader from "../../shaders/DNAbackbone/vertex.glsl";
import DNAbackboneFragmentShader from "../../shaders/DNAbackbone/fragment.glsl";
import Environment from "./Environment.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export default class HomeScene {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    // this.camera = this.experience.camera.instance;
    this.group = new THREE.Group();
    this.group.name = "HomeScene";

    // this.textDepth = 0.1;
    this.frontPanelDepth = -3; // in z position, not subtracted
    this.frontPanelHeight = 1.8;
    this.instructionUpMargin = 0.35;
    this.frontPanelScale = 0.5;
    // this.rightNudge = 0.05

    this.logoHeight = 1.85;
    this.ringSize = 0.35;
    this.ringThickness = 0.05;
    this.ringMargin = 0.15;
    this.ringDepth = 0.1;
    this.logoUpOffset = 0.035;

    this.orbSize = 0.1;
    //this.ringMaterial is a white mesh basic material
    // this.ringMaterial = new THREE.MeshBasicMaterial({
    //   color: 0xffffff,
    //   side: THREE.DoubleSide,
    // });

    this.frontPanel = new THREE.Group();
    this.resources.on("ready", () => {
      // Setup
      console.log("resources ready");
      this.environment = new Environment();
      this.stars = new Stars();
      this.floor = new Floor();
      this.butterfliesEntity = new Butterflies();
      this.textMaterial = new THREE.MeshMatcapMaterial();
      // this.textMaterial = new THREE.MeshLambertMaterial();

      this.textMaterial.color = new THREE.Color(0xffffff);
      this.textMaterial.side = THREE.DoubleSide;
      this.textMaterial.matcap = this.resources.items.matcapTexture2;
      /**
       * Text and Logo, in front panel
       */

      // lyd.io text
      const lydioTextGeometry = new TextGeometry("lyd.io", {
        font: this.resources.items.font,
        size: 0.5,
        height: 0.1,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.01,
        bevelSize: 0.01,
        bevelOffset: 0,
        bevelSegments: 5,
      });
      lydioTextGeometry.center();

      const lydioTextMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
      });
      this.lydioTextMesh = new THREE.Mesh(lydioTextGeometry, this.textMaterial);

      this.frontPanel.add(this.lydioTextMesh);
      //get width of lydioTextGeometry
      const lydioTextGeometryBox = new THREE.Box3().setFromObject(
        new THREE.Mesh(lydioTextGeometry, this.textMaterial)
      );
      const lydioTextWidth = lydioTextGeometryBox.max.x;
      const lydioTextHeight = lydioTextGeometryBox.max.y;
      // lydioTextMesh.position.set(-1 * lydioTextWidth * 0.5, 0, 0);
      // logo

      const ringShape = new THREE.Shape();
      ringShape.absarc(0, 0, this.ringSize, 0, Math.PI * 0.5, false);
      ringShape.absarc(0, 0, this.ringSize, Math.PI * 0.5, Math.PI, false);
      ringShape.absarc(0, 0, this.ringSize, Math.PI, Math.PI * 1.5, false);
      ringShape.absarc(0, 0, this.ringSize, Math.PI * 1.5, Math.PI * 2, false);
      const holeShape = new THREE.Shape();
      holeShape.absarc(
        0,
        0,
        this.ringSize - this.ringThickness,
        0,
        Math.PI * 0.5,
        true
      );
      holeShape.absarc(
        0,
        0,
        this.ringSize - this.ringThickness,
        Math.PI * 0.5,
        Math.PI,
        true
      );
      holeShape.absarc(
        0,
        0,
        this.ringSize - this.ringThickness,
        Math.PI,
        Math.PI * 1.5,
        true
      );
      holeShape.absarc(
        0,
        0,
        this.ringSize - this.ringThickness,
        Math.PI * 1.5,
        Math.PI * 2,
        true
      );
      ringShape.holes.push(holeShape);
      const extrudeSettings = {
        steps: 2,
        depth: 0.1,
        bevelEnabled: false,
      };

      this.lydioRing = new THREE.Mesh(
        new THREE.ExtrudeGeometry(ringShape, extrudeSettings),
        this.textMaterial
      );
      this.lydioRing.position.set(
        -1 * lydioTextWidth - this.ringSize - this.ringMargin,
        this.logoUpOffset,
        -this.ringDepth * 0.5
      );
      this.frontPanel.add(this.lydioRing);
      this.lydioOrb = new THREE.Mesh(
        new THREE.SphereGeometry(this.orbSize, 32, 32),
        new THREE.MeshMatcapMaterial({
          matcap: this.resources.items.sphere3,
        })
      );
      this.lydioOrb.position.copy(this.lydioRing.position);
      this.lydioOrb.position.z += this.ringDepth * 0.5;
      //
      const lydioRightShift = 0.5 * (this.ringSize * 2 + this.ringMargin);
      this.lydioTextMesh.position.x += lydioRightShift;
      this.lydioRing.position.x += lydioRightShift;
      this.lydioOrb.position.x += lydioRightShift;

      this.frontPanel.add(this.lydioOrb);
      this.frontPanel.position.set(
        0,
        this.frontPanelHeight,
        this.frontPanelDepth
      );

      //

      const instruction = this.experience.mobileVersion
        ? "(hit the button at the top)"
        : "(pull the trigger and flick up)";

      // instruction text
      const instructionGeometry = new TextGeometry(instruction, {
        font: this.resources.items.font,
        size: this.experience.mobileVersion ? 0.1 : 0.2,
        height: 0.05,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.01,
        bevelSize: 0.01,
        bevelOffset: 0,
        bevelSegments: 5,
      });
      instructionGeometry.center();

      const instructionMaterial = new THREE.MeshLambertMaterial({
        color: 0xffffff,
      });
      const instructionMesh = new THREE.Mesh(
        instructionGeometry,
        this.textMaterial
      );
      instructionMesh.position.set(
        0.05,
        -1 * lydioTextHeight - this.instructionUpMargin,
        0
      );

      this.frontPanel.add(instructionMesh);
      this.frontPanel.scale.set(
        this.frontPanelScale,
        this.frontPanelScale,
        this.frontPanelScale
      );

      this.group.add(this.frontPanel);

      /**
       * Particles
       */
      const lineLength = 5;
      const points = [];
      const size = [];
      const thickness = 0.2;
      const speed = 1.0;
      const amplitude = 1.0;
      const DNA = new THREE.Group();
      for (let i = -lineLength; i < lineLength; i += 0.05 * Math.random()) {
        points.push(
          new THREE.Vector3(
            Math.random() * thickness,
            Math.random() * thickness,
            i
          )
        );
      }
      this.material = new THREE.ShaderMaterial({
        vertexShader: DNAbackboneVertexShader,
        fragmentShader: DNAbackboneFragmentShader,
        uniforms: {
          uTime: { value: 0.0 },
          offset: { value: 0.0 },
          speed: { value: speed },
          amplitude: { value: amplitude },
        },
        side: THREE.DoubleSide,
      });
      this.material2 = new THREE.ShaderMaterial({
        vertexShader: DNAbackboneVertexShader,
        fragmentShader: DNAbackboneFragmentShader,
        uniforms: {
          uTime: { value: 0.0 },
          offset: { value: 1.0 },
          speed: { value: speed },
          amplitude: { value: amplitude },
        },
        side: THREE.DoubleSide,
      });
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const backbone1 = new THREE.Mesh(geometry, this.material);
      const backbone2 = new THREE.Points(geometry, this.material2);
      DNA.add(backbone1);
      DNA.add(backbone2);
      DNA.rotation.x += 1.111111;
      DNA.scale.set(0.5, 0.5, 0.5);

      this.particles = new THREE.Group();
      for (let i = 0; i < 6; i += 0.12) {
        var DNA2 = DNA.clone();
        DNA2.position.y += i / 10;
        DNA2.rotation.y += Math.random();
        DNA2.rotation.x += 1.0 * (Math.random() - 0.0) + i / 4;
        this.particles.add(DNA2);
      }
      this.particles.scale.set(4.5, 4.5, 4.5);
      // this.group.add(this.particles);

      // add axes helper to scene
      // this.scene.add(new THREE.AxesHelper());

      this.scene.add(this.group);
      this.ready = true;
    });
  }
  update() {
    if (this.ready && this.group.visible) {
      this.butterfliesEntity.update();
      // let t = Date.now() - this.experience.time.start;
      let t = this.experience.clock.getElapsedTime();
      this.material.uniforms.uTime.value = t / 1000;
      this.material2.uniforms.uTime.value = t / 1000;
      this.material.uniforms.amplitude.value = 1.0 + 0.2 * Math.sin(t / 4000);
      this.material2.uniforms.amplitude.value = 1.0 + 0.2 * Math.sin(t / 4000);
    }
  }
  hide() {
    this.group.visible = false;
  }
  show() {
    this.group.visible = true;
  }
}
