varying vec3 vPosition;
varying vec2 vUv;
uniform float flickDistance;
float circle(in vec2 _st, in float _radius){
    vec2 dist = _st-vec2(0.5);
	return 1.-smoothstep(_radius-(_radius*0.01),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*4.0);
}
void main() {
    vec3 color = vec3(1.0);
    vec2 st = vUv;
    // st.x *= 2.0;
    // st.y *= 2.0;
    // st.x -= 1.0;
    // st.y -= 1.0;
    // st.x *= 0.5;
    // st.y *= 0.5;
    // st.x += 0.5;
    // st.y += 0.5;
    float c = circle(st, 0.5);
    float c2 = circle(st, 0.44);
    c = c - c2;
    color = vec3(c);
    // c *= flickDistance;
    gl_FragColor = vec4(color,c);
}   

// void main() {
//     vec3 color = vec3(1.0);
//     // color = color1;

//     gl_FragColor = vec4(color,1.0);
// }