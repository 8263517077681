import * as THREE from "three";
import horizontalGridVertexShader from "../../shaders/horizontalGrid/vertex.glsl";
import horizontalGridFragmentShader from "../../shaders/horizontalGrid/fragment.glsl";
import Experience from "../Experience.js";

export default class Floor {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.group = new THREE.Group();

    this.width = 10;
    this.depth = 10;
    this.walkingRadius = 1.18;
    this.height = 0.1;
    this.blockSize = 0.35;

    this.tileGeometry = new THREE.BoxGeometry(
      this.blockSize,
      this.height,
      this.blockSize
    );

    this.textMaterial = new THREE.MeshMatcapMaterial();
    // this.textMaterial = new THREE.MeshLambertMaterial();

    this.textMaterial.color = new THREE.Color(0xffffff);
    this.textMaterial.side = THREE.DoubleSide;
    this.textMaterial.matcap = this.resources.items.matcapTexture2;
    // p5js style map function
    this.mapRange = function (value, a, b, c, d) {
      // first map value from (a..b) to (0..1)
      value = (value - a) / (b - a);
      // then map it from (0..1) to (c..d) and return it
      return c + value * (d - c);
    };

    for (
      let x = (-1 * this.width) / 2;
      x < this.width / 2.0;
      x += this.blockSize
    ) {
      for (
        let z = (-1 * this.depth) / 2;
        z < this.depth / 2.0;
        z += this.blockSize
      ) {
        const distance = Math.sqrt(x * x + z * z);
        if (distance < this.width * 0.5) {
          const cube = new THREE.Mesh(this.tileGeometry, this.textMaterial);
          cube.position.x = x;
          cube.position.z = z;
          //distance from 0,0

          if (distance > this.walkingRadius) {
            // cube.visible = false;
            // falloff value. 1 when it is distance is close to this.walkingRadius, 0 when distance is close to maxDistance
            let falloff = this.mapRange(
              distance,
              this.walkingRadius,
              this.width * 0.5,
              1,
              0
            );
            cube.scale.x *= falloff - 0.05;
            cube.scale.z *= falloff - 0.05;
            cube.scale.y *= falloff - 0.05;
            cube.position.y -= 0.1 * (1 - falloff);
            cube.rotation.x -= Math.PI * 0.5 * (1 - falloff);
            cube.rotation.y -= Math.PI * 0.5 * (1 - falloff);
            cube.rotation.z -= Math.random() * Math.PI * 2 * (1 - falloff);
          } else {
          }

          this.group.add(cube);
        }
      }
    }
    this.experience.world.HomeScene.group.add(this.group);
  }
}
