import * as THREE from "three";
import Experience from "../Experience.js";
import HomeScene from "./HomeScene.js";
import VideoPlayer from "./VideoPlayer.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.ResetDelay = 250;
    this.resources = this.experience.resources;
    this.HomeScene = new HomeScene();
    this.videoPlayer = new VideoPlayer();

    //event list
  }
  update() {
    this.HomeScene.update();
  }
  reset() {
    this.HomeScene.show();
    this.videoPlayer.hide();
  }
  nextVideo() {
    if (this.HomeScene.group.visible == true) {
      this.videoPlayer.playFirstVideo();
      this.HomeScene.hide();
    } else {
      this.videoPlayer.nextVideo();
    }
  }
}
