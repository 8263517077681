import * as THREE from "three";
import Experience from "../Experience.js";
import feed from "../../feed.js";
export default class VideoPlayer {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.group = new THREE.Group();
    this.video = document.getElementById("video");
    this.videoTexture = new THREE.VideoTexture(this.video);
    this.videoMaterial = new THREE.MeshBasicMaterial({
      map: this.videoTexture,
    });
    // this.videoTexture.minFilter = THREE.LinearMipMapLinearFilter;
    // this.videoTexture.magFilter = THREE.LinearFilter;
    this.group = new THREE.Group();
    //size used to be 9
    this.videoSphereGeoemetry = new THREE.SphereGeometry(9, 60, 40);
    this.videoSphereGeoemetry.scale(-0.3, 0.3, 0.3);
    // move up 2
    //used to be 1.2
    this.videoSphereGeoemetry.translate(0, 1.7, 0);
    this.videoSphereGeometry = this.videoSphere = new THREE.Mesh(
      this.videoSphereGeoemetry,
      this.videoMaterial
    );
    this.feed = feed;
    this.currentFeedIndex = 0;
    //log each video url in feed
    this.feed.forEach((video) => {
      console.log(video.streamURL);
    });
    //add videoSphere to videoPlayer
    this.group.add(this.videoSphere);
    this.group.visible = false;
    this.scene.add(this.group);
  }
  playVideo(index) {
    let hlsManifestURL =
      this.feed[index].hlsManifestURL + "?clientBandwidthHint=10";
    if (Hls.isSupported()) {
      var hls = new Hls({ enableWorker: false });
      window.hls = hls;
      console.log(hlsManifestURL);
      /**
       * wrap these two around a try catch and log the error
       *       hls.loadSource(hlsManifestURL);
      hls.attachMedia(this.video);
       */
      try {
        hls.loadSource(hlsManifestURL);
        hls.attachMedia(this.video);
      } catch (error) {
        console.log(error);
      }
    } else if (this.video.canPlayType("application/vnd.apple.mpegurl")) {
      this.video.src = hlsManifestURL;
    }
    this.video.play();
    this.video.muted = false;
    this.group.visible = true;
  }
  nextVideo() {
    //increment currentFeedIndex but loop back to 0 if at end of feed
    this.currentFeedIndex = (this.currentFeedIndex + 1) % this.feed.length;
    this.video.pause();
    this.video.currentTime = 0;
    //wait 50 ms to play next video
    setTimeout(() => {
      this.playVideo(this.currentFeedIndex);
    }, 100);
    // this.playVideo(this.currentFeedIndex);

    // this.video.currentTime = 0;
    console.log("playing next video");
  }
  playFirstVideo() {
    this.currentFeedIndex = 0;
    this.playVideo(this.currentFeedIndex);
  }
  previousVideo() {}
  hide() {
    this.currentFeedIndex = 0;
    this.group.visible = false;
    this.video.pause();
    this.video.currentTime = 0;
  }
  update() {}
}
